.wrapper-list-strategy {
  padding-top: 10px;

  h3 {
    font-size: 18px;
    margin: 0 0 20px;
    font-weight: 700;
  }
}

.list-strategy {
  background: #FFFFFF;
  box-shadow: 0px 2px 30px rgba(39, 52, 67, 0.05);
  border-radius: 20px;
  margin-bottom: 30px;

  .top-row-title, .row {
    display: flex;
    padding: 25px;
    font-size: 14px;
    font-weight: 700;
  }

  .top-row-title {
    color: #82879A;

    border: 1px solid #EFF3FB;

    .col {
      padding-left: 20px;
    }

    .col:not(.action) {
      background: url("../images/icon-save-title.svg") no-repeat 0 50%;

      &.name {
        padding-left: 45px;
        background-position: 30px 50%;
      }
    }

  }

  .row {
    color: #373A3E;

    .col.name {
      position: relative;
      padding-left: 30px;

    }

    .status {
      width: 10%;
      color: #21BA88;
      position: relative;
      padding-left: 10px;

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background: #21BA88;
        position: absolute;
        left: 0;
        top: 7px;
        border-radius: 50%;
      }
    }
  }

  .name {
    width: 40%;
    position: relative;
  }

  .status {
    width: 10%;

  }

  .amount {
    width: 10%;
  }

  .date {
    width: 10%;
  }

  .action {
    width: 10%;
    margin-left: auto;
    text-align: right;

    span , .edit{
      width: 15px;
      height: 15px;
      display: inline-block;
      cursor: pointer;
      margin: 0 5px 0 10px;
    }

    .edit {
      background: url("../images/pencil-2.svg") no-repeat;
    }

    .delete {
      background: url("../images/rubbish-bin.svg") no-repeat;
    }
  }

  .chosen {
    border: 1px solid #B0BBCB;
    box-sizing: border-box;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 2px;

    &.active {
      background: #21BA88 url("../images/arrow-check.svg") no-repeat 50%;
      border: 1px solid #37BCAE;
    }
  }
}

.saved-row-btn {
  justify-content: space-between;
  right: 0;
  width: calc(100% - 300px);
  padding: 10px 35px;

  .delete {
    border: 2px solid #b3b6c1;
    background: url(../images/rubbish-bin.svg) no-repeat 20px 50%;
    font-weight: 700;

    &:hover {
      background: #B3B6C1 url(../images/rubbish-bin-white.svg) no-repeat 20px 50%;
      color: #fff;
    }
  }
}