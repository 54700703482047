.form-new-strategy {
  padding-top: 10px;

  input:not([type=radio]) {
    height: 53px;
    font: 500 14px/50px 'Poppins', Arial, sans-serif;
    padding-left: 25px;


    &::placeholder {

      color: #A7ACC1;

    }
  }
}

.wrapper-fon {
  margin-bottom: 50px;

  h3 {
    margin: 0;
    padding: 15px;
  }

  textarea {
    border: none;
    height: 200px;
    border-radius: 25px;
  }
}

.error-data {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #EB5757;
}

.wrapper-data-form {
  .calendar-col {
    width: 35%;
    position: relative;

    .flex-layout {
      align-items: center;
    }

    .line {
      font-size: 26px;
      color: #373A3E;
    }
  }

  .amount {
    margin-bottom: 15px;
    background: url("../images/icon-dollar.svg") no-repeat 15px 50%;
    padding-left: 38px !important;
  }

  .wrapper-calendar {
    width: 45%;

    input {
      background: url("../images/icon-calendary.svg") no-repeat 15px 50%;
      padding-left: 38px;
    }

  }
}

.wrapper-style-text {
  display: flex;
  padding-left: 15px;
  margin-bottom: 10px;

  span {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 4px;
    margin: 4px 10px 4px 4px;

    &.active {
      box-shadow: 0px 5px 8px rgba(39, 52, 67, 0.14);
    }

  }

  .bold-text {
    background: url("../images/icon-bold.png") no-repeat 50%;
  }

  .underline-text {
    background: url("../images/icon-underline.png") no-repeat 50%;
  }

  .align-text {
    background: url("../images/icon-left-alignment.png") no-repeat 50%;
  }
}

label {
  position: relative;
cursor: pointer;
  input[type=radio] {
    position: absolute;
    width: 21px;
    height: 19px;
    left: 10px;
    top: 14px;
  }

  span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
    border-radius: 6px;
    background: #F1F4F9;
  }

  input[type=radio]:checked + span {
    background-color: #fff;
    box-shadow: 0px 12px 40px rgba(39, 52, 67, 0.1);

    color: #373A3E;
    font-weight: 600;
  }
}

.radio-group-three-row {

  label {

    input[type=radio] {
      top: 14px;
    }

    span {
      padding-bottom: 0;
      align-items: center;
      cursor: pointer;
    }
  }
}

.radio-group-two {
  width: 20%;
  margin-bottom: 30px;

  .flex-layout {
    justify-content: flex-start;
  }

  label {

    width: 155px;
    height: 155px;
    border-radius: 4px;
    position: relative;


    &:first-of-type {
      margin-right: 10px;



      span {
        background: #F1F4F9 url("../images/pie-chart.svg") no-repeat 50% 30%;
      }
    }

    &:last-of-type span {
      background: #F1F4F9 url("../images/graph-checked.svg") no-repeat 50% 30%;
    }


  }
}

.radio-group-three {
  width: 35%;

  .flex-layout {
    justify-content: flex-start;
  }

  .radio-group-three-row label {
    display: flex;
    height: 53px;

    width: 155px;
    border: 4px;
    justify-content: center;
    align-items: center;
    margin-right: 26px;

    &:last-of-type {
      margin-right: 0;
    }

  }


}

.wrapper-fon {
  padding: 40px 25px;
  margin-top: 30px;

  h5, label {
    font-size: 14px;
    color: #82879A;
    margin: 0 0 10px;
    font-weight: 400;
    display: inline-block;
  }
}
.radio-group-two  label input[type=radio]:checked + span{

}
label input[type=radio]:checked + span {


  &:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
    top: 17px;

    border-radius: 50%;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 20px;
    top: 22px;
    background: #fff;
    border-radius: 50%;
  }
}
.buyback-section {

  label input[type=radio]:checked + span {
    border: 2px solid #4438CD;
    &:before {
      background: linear-gradient(90deg, #2C69DF 0%, #4438CD 100%);
    }
  }
  .data-info-new-strategy .col {
    background: linear-gradient(90deg, #2C69DF 0%, #4438CD 100%);
  }
}

.raise-section {
  label input[type=radio]:checked + span {
    border: 2px solid #38CD86;
    &:before {
      background: linear-gradient(90deg, #22B4A4 0%, #38CD86 100%);
    }
  }

  .data-info-new-strategy .col {
    background: linear-gradient(90deg, #22B4A4 0%, #38CD86 100%);
  }
}

.data-info-new-strategy {


  color: #fff;
  display: flex;
  font-weight: 600;

  .col {
    width: 20%;
    padding: 15px;
    margin-right: 78px;

    box-shadow: 0px 2px 30px rgba(39, 52, 67, 0.06);
    border-radius: 6px;
    box-sizing: border-box;
  }

  h5 {
    color: #fff;
    font-weight: 600;
  }

  .price {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 6px;
    width: 113px;
    height: 52px;
    display: flex;
    font-size: 28px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 10px;

  }

  .remaining {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.react-datepicker-wrapper {
  width: 100%;

}

.row-price {
  justify-content: flex-start;

  .col-price {
    width: 155px;
    margin-right: 30px;
  }
}