@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  outline: none;

  &:focus {
    outline: none;
  }
}
img {
  vertical-align: top;
  border: 0;
  max-width: 100%;

}

header,
footer,
article,
section,
hgroup,
nav,
figure {
  display: block;
}

form,
fieldset,
button {
  margin: 0;
  padding: 0;
  border: none;
}

button {
  -webkit-appearance: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"], .select {
  box-sizing: border-box;
  font: 14px $primary-font-family;
  font-weight: 400;
  width: 100%;
  background: #fff;
  border: 1px solid #D6E2EC;
  border-radius: 25px;
  color: #58595B;
  padding: 0 10px;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus{
  box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1);
}
.error input{
  border-color: #EB5757;
}

.select {
  .css-z7hu84{
    display: flex;
    padding-left: 20px;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-tj5bde-Svg {
    fill: #000;

  }

  .css-yk16xz-control {
    border: none;
    border-radius: 25px;
  }
}

.select {
  cursor: pointer;
  padding: 0;
}

.select-chart-data {
  height: 40px;

}

textarea {
  font: 14px $primary-font-family;
  width: 100%;
  padding: 0 15px;
  height: 40px;
  border: 1px solid #D6E2EC;


  &:focus {
    border: 1px solid #00adef;


  }

  &.error {
    border-bottom: 1px solid #ec1b35;
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  outline: none;
  border: none;
}

html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font: 400 14px 'Poppins', Arial, sans-serif;
  height: 100%;
  background: #f8fafe;
}

.container {

}

h1 {
  margin: 0 0 30px;
  font-size: 24px;
  font-weight: 600;
}

.content {
  width: calc(100% - 300px);
  padding: 35px 45px;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  transition: width .5s linear;

}

.wrapper-auth {
  position: absolute;
  right: 55px;
  top: 25px;
  display: flex;
  align-items: center;
  z-index: 11;

  .name {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }

  .working-place {
    color: #A7ACC1;
    font-size: 13px;
  }
}

.auth-img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 2px solid #37BCAE;
  padding: 3px;
  box-sizing: border-box;
  margin-left: 10px;

  img {
    width: 100%;
  }
}

.logo {
  margin-bottom: 20px;
  display: block;
  margin-left: 25px;
}

.settings {
  background: #EEF2FA url("../images/icon-settings.svg") no-repeat 50%;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  margin-left: 10px;
  cursor: pointer;
}

.person-info {
  text-align: right;
}

aside {
  width: 300px;
  background: #0D0F39;
  padding: 35px 0 40px 10px;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;

  transition: left .5s linear;
  left: 0;
  position: fixed;

  nav a {
    display: block;
    color: #ffffff;
    text-decoration: none;
    height: 67px;
    line-height: 67px;
    padding-left: 60px;
    border-radius: 50px 0 0 50px;
    width: 100%;
    transition: width .5s;
    margin-left: auto;
    &.active {
      color: #58595B;
      position: relative;

      &.strategy-overview {
        background: #f8fafe url("../images/icon-overview-active.svg") no-repeat 20px 50%;
      }

      &.new-strategy {
        background: #f8fafe url("../images/icon-new-strategies-active.svg") no-repeat 20px 50%;
      }

      &.saved-strategy {
        background: #f8fafe url("../images/icon-saved-strategies-active.svg") no-repeat 20px 50%;
      }

      &.settlements {
        background: #f8fafe url("../images/icon-settlements-active.svg") no-repeat 20px 50%;
      }

      &.equity-tracking {
        background: #f8fafe url("../images/icon-tracking-active.svg") no-repeat 20px 50%;
      }

      &.reporting {
        background: #f8fafe url("../images/icon-reporting-active.svg") no-repeat 20px 50%;
      }

      &.help {
        background: #f8fafe url("../images/icon-help-active.svg") no-repeat 20px 50%;
      }

      //&:after, &:before {
      //  content: '';
      //  position: absolute;
      //  width: 48px;
      //  height: 43px;
      //  right: 0;
      //
      //}

      //&:before {
      //  bottom: 99%;
      //  background: url("../images/bg-active-top.png") no-repeat;
      //  background-size: contain;
      //}

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -30px;
        background: url("../images/bg-active.png") no-repeat 100%;
        background-size: contain;
        width: 26px;
        height: 128px;
      }
    }
  }
}


.hide-menu {
  width: 20px;
  height: 20px;
  background: url("../images/icon-hide.svg") no-repeat;
  font-size: 0;
  position: absolute;
  right: 30px;
  top: 50px;
  cursor: pointer;
  transform: rotate(0);
  transition: transform .3s linear;
  z-index: 11;
}

.full-width-container {
  .content {
    width: calc(100% - 100px);
    transition: width .5s linear;
  }

  aside {
    left: -200px;
    transition: left .5s linear;
    overflow: hidden;
  }

  .hide-menu {
    transform: rotate(180deg);
    transition: transform .3s linear;
    right: 41px;
  }

  aside nav a {
    animation: iconAnimate 0s .5s forwards !important;
    border-radius: 50px 0 0 50px;
    width: 100px;
    margin-left: auto;
    font-size: 0;
  transition: width .5s;

    &.active:after {
      right: 0;
    }
  }


  aside nav a.active:before, aside nav a.active:after {
    //animation: iconAnimate 0s .5s forwards !important;
  }

  .fixed-row-btns {
    width: calc(100% - 150px);
    transition: width .5s linear;
  }
}


.strategy-overview {
  background: url("../images/icon-overview.svg") no-repeat 20px 50%;
}

.new-strategy {
  background: url("../images/icon-new-strategies.svg") no-repeat 20px 50%;
}

.saved-strategy {
  background: url("../images/icon-saved-strategies.svg") no-repeat 20px 50%;
}

.settlements {
  background: url("../images/icon-settlements.svg") no-repeat 20px 50%;
}

.equity-tracking {
  background: url("../images/icon-tracking.svg") no-repeat 20px 50%;
}

.reporting {
  background: url("../images/icon-reporting.svg") no-repeat 20px 50%;
}

.help {
  background: url("../images/icon-help.svg") no-repeat 20px 50%;
}

.wrapper-fon, .wrapper-chart {
  background: #FFFFFF;
  box-shadow: 0px 2px 30px rgba(39, 52, 67, 0.05);
  border-radius: 20px;
  margin-bottom: 30px;

}

.wrapper-chart-without-fon {
  background: inherit;
  border-radius: 0;
}

.wrapper-chart {

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
  }

  .select-chart-data {
    width: 196px;
    position: relative;
    z-index: 1;

    .css-z7hu84 {
      padding-left: 40px;
      background: url('../images/icon-select.png') no-repeat 20px 50%;
    }
  }
}

.select .css-2613qy-menu {
  z-index: 1111;
  padding: 8px 26px;
  box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1) !important;
  border-radius: 15px !important;
}

h3 {
  font: 500 18px 'Poppins', Arial, sans-serif;
  margin: 0;
}

.chart-market-equity-performance {
  .settings {
    background: url("../images/settings.svg") no-repeat;
    width: 20px;
    height: 20px;
    margin-left: 20px;
    font-size: 0;
  }
}

.wrapper-select-settings {
  display: flex;
  align-items: center;
}

.wrapper-chart-label {
  margin-right: 20px;
}


.fixed-row-btns {
  position: fixed;
  bottom: 0;
  right: 45px;
  width: calc(100% - 390px);
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  transition: width .5s linear;


  button {
    border-radius: 25px;

    width: 168px;
    height: 42px;

    cursor: pointer;
    margin: 0 10px;
    font: 500 15px/40px 'Poppins', Arial, sans-serif;


  }




}
.bg-full{
  border: 2px solid #37BCAE;
  box-shadow: 0px 5px 10px rgba(55, 188, 174, 0.3);
  background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
  color: #fff;
  &:hover {
    box-shadow: none;
    background: none;
    color: #58595B;
  }
}
.bg-none{
  border: 2px solid #37BCAE;
  color: #505050;
  background: #fff;
  &:hover {
    box-shadow: 0px 5px 10px rgba(55, 188, 174, 0.3);
    background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
    color: #fff;
  }
}
.flex-layout {
  display: flex;
  justify-content: space-between;

  .wrapper-chart {
    width: 48%;
  }

}

@keyframes iconAnimate {
  from {
    //background-color:inherit;
    content: '';
    background-position: 20px 50%;
  }
  to {
    //background-color:inherit;
    content: none;
    background-position: 45% 50%;
  }
}

.chart-img {
  img {
    width: 100%;
  }
}

.popup-overlay {

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: fadePopup .3s ease-out forwards;
  opacity: 0;
z-index: 11;
  h2 {
    font-size: 34px;
    text-align: center;
    font-weight: 400;
  }

  .wrapper-popup {
    background: #FFFFFF;
    width: 630px;
    border-radius: 20px;
    position: relative;
    padding: 80px 30px 70px;
    text-align: center;
    box-sizing: border-box;
  }

  .close-popup {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 15px;
    height: 15px;
    background: url("../images/close_button.png") no-repeat;
    cursor: pointer;
  }

  button {
    border-radius: 60px;
    width: 152px;
    height: 49px;
    border: 1px solid #37BCAE;

    line-height: 49px;
    margin: 0 20px;
    cursor: pointer;


    //&:hover {
    //  background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
    //  box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1);
    //  color: #fff;
    //}

    &.ok-btn {
      font-size: 0;
      background-image: url("../images/ok-arrow.png"), linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
      background-repeat: no-repeat;
      background-position: 50%;
      //color: #58595B;
      &:hover {
        background: url("../images/ok-arrow-hover.png") no-repeat 50%;
      }
    }
  }
}


@keyframes fadePopup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.select div[class*="menu"]{
  z-index: 99;
}
.select div[class*="menu"] div div:hover {
  background: none !important;
  color: #37BCAE !important;
  cursor: pointer;
}
.select div[class*="Control"],  
.select div[class*="ValueContainer"]{
  height: 100%;
}
[class*="IndicatorsContainer"]{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}



.apexcharts-xaxis{
  background: red;
}

.apexcharts-xaxis-label{
  display: block;
  width: 100%;
  background: red;
fill: #8E94A9;
}


.apexcharts-tooltip {
  background: #0D0F39 !important;
  border-radius: 4px !important;
  font-size: 11px;
  font-family: 'Poppins';
  position: relative;
  overflow: visible !important;
}
.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title{
  background: #0D0F39 !important;
}
.apexcharts-tooltip:after{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #0D0F39 transparent transparent transparent;
  top: 100%;
  //background: linear-gradient(99.66deg, #0D0F39 0.77%, #133930 119.37%) !important;
  position: absolute;
  left: 50% ;
  transform: translateX(-50%);
}
.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title{
  border-radius: 4px;
}

.wrapper-amount{
  position: relative;
}

.submit-form-btn{
  border: 1px solid #d6e2ec;
  border-radius: 25px;
  //color: #58595b;
  padding: 0 10px;
  height: 53px;
  line-height: 50px;
  background: none;
  width: 132px;
  text-align: center;
  margin-left: 43px;
  align-self: center;
  cursor: pointer;
  font-size: 16px;
}
.submit-form-btn {
  border: 2px solid #37bcae;
  box-shadow: 0px 5px 10px rgba(55 ,188, 174 ,  0.3);
  background: linear-gradient(
                  90deg, #37BCAE 0%, #38CD98 100%);
  //color: #fff;
}
.buyback-section .submit-form-btn{
  border: 2px solid #4438CD;
  background: linear-gradient(
                  90deg, #2C69DF 0%, #4438CD 100%);
  box-shadow: 0px 5px 10px rgba(166, 191, 242, 1);
  &:hover {
    box-shadow: none;
    background: none;
    color: #58595b;
  }
}
.submit-form-btn[disabled]{
  opacity: 0.6;
  pointer-events: none;
}
.submit-form-btn[disabled]:hover{
  box-shadow: none;
}

.amount-chart{
  color: #58595b;

  position: relative;
  right: 82px;
  font-size: 20px;
  top: 36px;
}

.no-strategies{
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

.changeColumn{
  background: red;
}
.apply-btn{
  text-align: center;
  margin: 10px 0;
  border-radius: 50px;
  height: 40px !important;
  padding: 0 !important;
  /* margin: 0; */
  display: block;
  line-height: 35px !important;
  width: 100px;
  color: #fff;
border: none;
  cursor: pointer;
}
.buyback-section .apply-btn{

  background: #4438CD;

}
.raise-section .apply-btn{

  background: #37bcae;

}

.textEdit::-webkit-outer-spin-button,
.textEdit::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.textEdit{
  margin: 0 !important;
  padding: 0 10px !important;
}


.react-datepicker__navigation--next{
  background: url(../images/arrow-cal.svg) no-repeat 14px -2px;
  transform: rotate(-90deg);
  background-size: 12px;
}
.react-datepicker__navigation--previous{
  background: url(../images/arrow-cal.svg) no-repeat 5px -2px;
  transform: rotate(90deg);
  background-size: 12px;
}