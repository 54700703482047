.wrapper-login {
  height: 100vh;
  .logo {
    position: absolute;
    left: 100px;
    top: 40px;
    z-index: 11;
  }

  .sing-up-btn {
    right: 100px;
    top: 40px;
    width: 152px;
    height: 49px;
    background: #FFFFFF;
    box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1);
    border-radius: 60px;
    color: #58595B;
    font-size: 13px;
    text-decoration: none;
    position: absolute;
    text-align: center;
    line-height: 49px;
  }

  .col {
    width: 50%;
  }

  .login-block {
    background: #fff;
  }
}

.login-block {
  display: flex;
  align-items: center;
  position: relative;

  .wrapper-link{
    position: absolute;
    bottom: 50px;
    left: 100px;
    a{
      margin-right: 74px;
      color:#58595B;
      font-size: 13px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .error{
    color: red;
    position: relative;
    top: -13px;
    text-align: center;
  }
  h1 {
    font-size: 34px;
    color: #58595B;
    font-weight: 400;
    margin-bottom: 50px;
    text-align: center;
  }

  .wrapper-login-form {
    width: 360px;
    margin: 0 auto;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #D6E2EC;
    box-sizing: border-box;
    border-radius: 100px;
    height: 54px;
    margin-bottom: 30px;
  }

  button[type=submit] {
    width: 228px;
    height: 57px;
    margin: 0 auto 30px;
    background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
    box-shadow: 0px 14px 25px rgba(55, 188, 174, 0.3);
    border-radius: 60px;
    border: 1px solid #37BCAE;
    display: block;
    color: #fff ;
    cursor: pointer;
    font: 400 15px 'Poppins', Arial, sans-serif;
    &:hover{
      box-shadow: none;
      background: none;
      color: #58595B;

    }
  }
  label{
    color: #A7AFB7;
    font-size: 13px;
    margin-bottom: 5px;
    display: block;
  }
  .wrapper-password{
    position: relative;
  }
  .forgot-password{
    font-size: 12px;
    color: #58595B;
    position: absolute;
    right: 0;
    top: 0;
  }
  .info{
    display: flex;
    font-size: 13px;
    text-align: center;
    color: #000;
    justify-content: center;
    p{
      margin: 0;
    }
    a{
      color: #37BCAE;
      margin-left: 10px;
    }
  }
}

.slider-block {
  padding-top: 180px;
  text-align: center;
  width: 100%;
  color: #58595B;
  font-size: 13px;

  img {
    margin: 0 auto;
  }

  h3 {
    color: #58595B;
    font-size: 26px;
  }

  .slick-dots {
    display: flex !important;

    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    button {
      font-size: 0;
      background: none;
      border: none;
    }

    li {
      border: 2px solid #58595B;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin: 0 3px;
      &.slick-active {
        background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
        border: 1px solid #37BCAE;
      }
    }
  }


}
.log-out-btn{
  color: #fff;
  position: absolute;
  bottom: 30px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  background: url('../images/logout.svg') no-repeat;
  left: 30px;
}