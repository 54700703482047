.row-tab {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e1e5;
  font-size: 16px;
  color: #B3B6C1;
  font-weight: 500;
  margin-bottom: 30px;

  span {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;

    &.active {
      color: #37BCAE;
      position: relative;

      &:after {
        content: '';
        background: #37BCAE;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -12px;
        left: 0;
      }
    }
  }
}

.col-financial-year {
  display: flex;
  align-items: center;
  //width: 40%;
  .select {
    width: 150px;
  }

  label {
    //display: block;
    //width: 100%;
    //text-align: right;
    margin-right: 20px;
    font-size: 13px;
    color: #A7ACC1;
  }
}

.row-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;


}

.choose-strategy {
  width: 265px;
}

.row-capital {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 20px;
  .wrapper-col-category {
    width: 33.33%;
    margin-bottom: 10px;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .col-category {

    border-radius: 6px;
    box-shadow: 0px 2px 30px rgba(39, 52, 67, 0.06);
    color: #fff;
    padding: 28px 30px 30px 160px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  .entrance .col-category {
    background: linear-gradient(90deg, #22B4A4 0%, #38CD86 100%);


  }

  .price {
    display: block;
    //height: 20px;
    font-size: 28px;
    font-weight: 600;
    margin: 5px 0;
  }

  .exit .col-category {
    background: linear-gradient(90deg, #2C69DF 0%, #4438CD 100%);
  }

  h5 {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }

  .remaining {
    font-size: 12px;
  }

  .income {
    color: #58595B;
    font-size: 15px;
    padding: 0px 8px;
    border-radius: 4px;
    background: #fff;
    position: absolute;
    right: 21px;
    top: 27px;
  }

}
.icon-category{
  position: absolute;
  width: 110px;
  height: 89px;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 0;
}
.category1 .icon-category{
  background: url("../images/img-category1.svg") 20px 50% no-repeat;
}
.category2 .icon-category{
  background: url("../images/img-category2.svg") 20px 50% no-repeat;
}
.category3 .icon-category{
  background: url("../images/img-category3.svg") 20px 50% no-repeat;
}
.category4 .icon-category{
  background: url("../images/img-category4.svg") 20px 50% no-repeat;
}
.category5 .icon-category{
  background: url("../images/img-category5.svg") 20px 50% no-repeat;
}
.category6 .icon-category{
  background: url("../images/img-category6.svg") 20px 50% no-repeat;
}

