.wrapper-signup{
  background: linear-gradient(101.99deg, #0D0F39 0.86%, #133930 115.02%);
  color: rgba(255,255,255,0.8);

  .holder{
    height: 100vh;
    min-height: 400px;
    background: url('../images/decoration-2.svg') no-repeat 50% 100%;
    background-size: 1280px auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px 200px;
    box-sizing: border-box;
  }

  .logo {
    position: absolute;
    left: 100px;
    top: 40px;
    z-index: 11;
  }

  .btn-white {
    right: 100px;
    top: 40px;
    width: 152px;
    height: 49px;
    background: #fff;
    box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1);
    border-radius: 60px;
    color: #58595B;
    font-size: 13px;
    text-decoration: none;
    position: absolute;
    text-align: center;
    line-height: 49px;
  }
}
.wrapper-signup-form{
  font-size: 18px;
  line-height: 1.6;
  padding: 0 0 30px;

  h1{
    font-size: 56px;
    font-weight: 500;
    margin: 0 0 30px;
    text-align: center;

    span{
      background: -webkit-linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p{
    margin: 0 0 40px;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"]{
    background: rgba(255,255,255,0.1);
    border: none;
    box-sizing: border-box;
    border-radius: 100px;
    font: 13px 'Poppins', Arial, sans-serif;
    height: 54px;
    color: rgba(255,255,255,0.8);
    width: 48.6%;
    padding: 0 30px;
  }
  input:-moz-placeholder{
    color: #A7AFB7;
    opacity: 1;
  }
  input:-ms-input-placeholder{
    color: #A7AFB7;
    opacity: 1;
  }
  input::-webkit-input-placeholder{
    color: #A7AFB7;
    opacity: 1;
  }
  input:-ms-input-placeholder{
    color: #A7AFB7;
    opacity: 1;
  }
  input:focus:-moz-placeholder{
    opacity: 0.5;
  }
  input:-ms-input-placeholder:focus{
    opacity: 0.5;
  }
  input:focus::-webkit-input-placeholder{
    opacity: 0.5;
  }
  input:-ms-input-placeholder:focus{
    opacity: 0.5;
  }
  .row{
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;
  }
  button[type=submit] {
    width: 228px;
    height: 57px;
    margin: 0 auto 30px;
    background: linear-gradient(90deg, #37BCAE 0%, #38CD98 100%);
    box-shadow: 0px 14px 25px rgba(14, 23, 69, 0.1);
    border-radius: 60px;
    border: 1px solid #37BCAE;
    display: block;
    color: #fff ;
    cursor: pointer;
    font: 400 15px 'Poppins', Arial, sans-serif;
    pointer-events: none;
    &:hover{
      box-shadow: none;
    }
  }
}